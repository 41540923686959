<template>
  <div class="New__Entity">
    <div class="SideMenu__Header">
      <h1>הוספת רכב</h1>
    </div>
    <div class="SideMenu__Content">
      <FieldInlineText
        label="לוחית רישוי"
        :required="$v.model.PlateNumber.$anyError"
        v-model="$v.model.PlateNumber.$model"
      />

      <FieldInlineSelect
        label="סוג"
        :required="$v.model.VehicleType.$anyError"
        :options="[
          { Value: 2, Text: 'פרטי' },
          { Value: 1, Text: 'מסחרי' },
        ]"
        null-is-value="true"
        v-model="$v.model.VehicleType.$model"
      />

      <FieldInlineSelect
        label="יצרן"
        :filterable="true"
        :clearable="true"
        :required="$v.model.Manufacturer.$anyError"
        :optionsAsync="getManufacturers"
        null-is-value="true"
        v-model="$v.model.Manufacturer.$model"
      />

      <FieldInlineText
        label="דגם"
        :required="$v.model.Model.$anyError"
        v-model="$v.model.Model.$model"
      />

      <FieldInlineText
        label="שנה"
        :required="$v.model.Year.$anyError"
        v-model="$v.model.Year.$model"
      />

      <FieldInlineText
        label="צבע"
        :required="$v.model.Color.$anyError"
        :max="10"
        v-model="$v.model.Color.$model"
      />

      <FieldInlineDateTime
        label="תאריך התחלת בעלות"
        v-model="$v.model.Ownership.StartDate.$model"
      />
    </div>
    <div class="SideMenu__Footer">
      <div>
        <button :disabled="isLoading" @click="onCancel" class="close" type="">
          ביטול
        </button>
        <b-button :loading="isLoading" @click="onSave" class="create" type="">
          שמור
        </b-button>
      </div>
      <b-button
        :disabled="isLoading || !model.PlateNumber"
        :loading="isLoading"
        @click="fetchVehicleData"
        class="close"
        type=""
      >
        משוך נתוני רכב
      </b-button>
    </div>
  </div>
</template>

<script>
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import FieldInlineText from "@/components/Fields/FieldInlineText.vue";
import FieldInlineDateTime from "@/components/Fields/FieldInlineDateTime.vue";
import ManufacturersService from "@/services/ManufacturersService";
import VehicleService from "@/services/VehicleService";
import { required } from "vuelidate/lib/validators";

export default {
  name: "NewVehicleEntity",
  components: {
    FieldInlineSelect,
    FieldInlineText,
    FieldInlineDateTime,
  },
  validations: {
    model: {
      PlateNumber: {
        required,
      },
      Manufacturer: {
        required,
      },
      Ownership: {
        StartDate: { required },
      },
      Model: {
        required,
      },
      Year: {},
      Color: {},
      VehicleType: {
        required,
      },
    },
  },
  data() {
    return {
      isLoading: false,
      model: {
        PlateNumber: null,
        Manufacturer: null,
        Model: null,
        Year: null,
        Color: null,
        VehicleType: null,
        Ownership: {
          StartDate: null,
        },
      },
    };
  },
  methods: {
    fetchVehicleData() {
      this.isLoading = true;
      VehicleService.getVehicleDetails(this.model.PlateNumber)
        .then((r) => {
          if (!r.data) {
            this.$buefy.toast.open({
              type: "is-success",
              message:
                "לא היה ניתן למצוא את הרכב. יש להקליד את הפרטים באופן ידני",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.model.Model = r.data.kinuy_mishari || r.data.degem_nm;
            this.model.Year = r.data.shnat_yitzur;
            this.model.Color = r.data.tzeva_rechev.slice(0, 10);
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    onSearchVehicles({ search, loading, setData }) {
      if (search.length > 4) {
        loading(true);
        return VehicleService.searchPlateNumber(search)
          .then((r) => {
            setData(r.data.map((i) => ({ Value: i.Id, Text: i.Name })));
          })
          .finally(() => {
            loading(false);
          });
      }
      return null;
    },
    getManufacturers() {
      return ManufacturersService.get;
    },
    onSave() {
      const self = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;
      const data = { ...this.model };
      // console.log("data.Ownership.StartDate:", data.Ownership.StartDate);
      // data.Ownership.StartDate = new Date(
      //   new Date(data.Ownership.StartDate).getTime() + 3 * 60 * 60 * 1000
      // ); // return the Isreal timezone (3 hours more)
      data.Manufacturer = { Id: this.model.Manufacturer };
      data.VehicleType = { Id: this.model.VehicleType };
      data.Ownership.Id = this.$store.state.profile.Id;

      VehicleService.addVehicle(data)
        .then((r) => {
          self.$buefy.toast.open({
            type: "is-success",
            message: `הרכב נשמר בהצלחה. לצפיה ברכב <a style="color:white;text-decoration: undeline;" href="vehicles/${r.data.Id}">לחץ כאן </a>`,
            duration: 10000,
          });
          self.$emit("onSave");
        })
        .catch(() => {
          this.$buefy.toast.open({
            type: "is-error",
            message: "השמירה נכשלה, יש לנסות שוב.",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
          // VehicleService.get(true); // cache clear for filters
        });
    },
    onCancel() {
      this.$emit("onCancel");
    },
  },
};
</script>

<style scoped lang="scss">
.New__Entity {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.notification {
  position: absolute;
  bottom: 43px;
  width: 100%;
  border-radius: 0;

  ::v-deep .media-content {
    text-align: center;
  }

  &.is-info {
    background-color: #01afb4;
  }
  // &.is-warrent {
  //     background-color: orange;
  // }
}
.SideMenu__Footer {
  justify-content: space-between;
}
</style>
